<template>
    <v-card :key="modalKey">
      <v-card-title>
        <span class="headline">Création d'un sous-module</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row> 
            <v-col >
              <v-autocomplete
                ref="userSelect"
                :items="users"
                :item-text="item => `${item.firstname} ${item.lastname}`"
                :label="'Membres GTP*'"
                multiple
                v-model="infoSousModule.usersSelected"
                :rules="[usersRules]"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.firstname + ' ' + data.item.lastname }}
                </template>
                <template slot="item" slot-scope="data">
                  {{ data.item.firstname + ' ' + data.item.lastname }}
                </template>
              </v-autocomplete> 
            </v-col>
            <v-col>
              <v-text-field v-model="infoSousModule.version" label="Version*" :rules="[versionRules]"></v-text-field>
            </v-col>
          </v-row>
          <v-row> 
            <v-col>
              <v-text-field v-model="infoSousModule.sousModule" label="Sous-module*" :rules="[sousModuleRules]"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="infoSousModule.technologie" label="Technologie*"  :rules="[technologieRules]"></v-text-field>
            </v-col>
          </v-row> 
          <v-row>
            <v-col>
              <v-textarea
                v-model="infoSousModule.description"
                label="Description*"
                :rules="[descriptionRules]"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeModal">Annuler</v-btn>
        <v-btn color="success" text @click="saveSousModule" :disabled="!isValidate">Créer</v-btn>
      </v-card-actions>
    </v-card>
</template>
<script>

import UserService from '@/Services/UserService';
import * as Vuex from "vuex";
import ModuleService from "@/Services/SupportVision/ModuleService";

export default {
  name: "createSousModule",
  components: {
    ClientPickerSelect: () => import("@/Components/Commons/Inputs/Client/ClientPickerSelect"),
  },
  data() {
    return {
      users: [],
      infoSousModule: {
        usersSelected: [],
        version: '',
        sousModule: '',
        technologie: '',
        description: ''
      },
      modalKey: 0,
      usersRules: (v) => v && v.length > 0 || 'Ce champ est requis',
      versionRules: (v) => v && v.length > 0 || 'Ce champs est requis',
      sousModuleRules: (v) => v && v.length > 0 || 'Ce champs est requis',
      technologieRules: (v) => v && v.length > 0 || 'Ce champs est requis',
      descriptionRules: (v) => v && v.length > 0 || 'Ce champs est requis',
    }
  }, 
  computed: {
    isValidate() {
      return !!this.infoSousModule.version && !!this.infoSousModule.usersSelected.length && !!this.infoSousModule.sousModule && !!this.infoSousModule.technologie && !!this.infoSousModule.description;}
  },
  methods: {
    reset() {
      this.infoSousModule = {
        usersSelected: [],
        version: '',
        sousModule: '',
        technologie: '',
        description: ''
      }
      this.modalKey += 1;
      this.usersRules = (v) => v && v.length > 0 || 'Ce champ est requis';
      this.versionRules = (v) => v && v.length > 0 || 'Ce champs est requis';
      this.sousModuleRules = (v) => v && v.length > 0 || 'Ce champs est requis';
      this.technologieRules = (v) => v && v.length > 0 || 'Ce champs est requis';
      this.descriptionRules = (v) => v && v.length > 0 || 'Ce champs est requis';
    },
    closeModal() {
      this.reset();
      this.$emit('close-modal-create-sous-module');
    }, 
    async saveSousModule() {
      await ModuleService.createSousModule(this.infoSousModule);
      this.$emit('refresh');
      this.closeModal();
    },
    async refreshBasesList() {
      const users = await UserService.getUsers().then((res) => {
        this.users = res;
      });  
    },   
  },
  async mounted() { 
    await this.refreshBasesList();
  }
}
</script>

<style scoped> 
</style>